import { template as template_aa7544ffbcf845e0bd4dad0258b6410f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_aa7544ffbcf845e0bd4dad0258b6410f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
