import { template as template_b489335eede3437188ec9d8d1fe00e91 } from "@ember/template-compiler";
const FKText = template_b489335eede3437188ec9d8d1fe00e91(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
